/* ###### Position  ###### */

/* ###### 9.7 Position   ###### */

.pos-absolute {
	position: absolute;
}
.pos-relative {
	position: relative;
}
.pos-fixed {
	position: fixed;
}
.pos-static {
	position: static;
}
.pos-absolute-f {
	position: absolute !important;
}
.pos-relative-f {
	position: relative !important;
}
.pos-fixed-f {
	position: fixed !important;
}
.pos-static-f {
	position: static !important;
}
.t-0 {
	top: 0px;
}
.r-0 {
	right: 0px;
}
.b-0 {
	bottom: 0px;
}
.l-0 {
	left: 0px;
}
.t--0 {
	top: -0px;
}
.r--0 {
	right: -0px;
}
.b--0 {
	bottom: -0px;
}
.l--0 {
	left: -0px;
}
.a-0 {
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.x-0 {
	left: 0px;
	right: 0px;
}
.y-0 {
	left: 0px;
	right: 0px;
}
.t-5 {
	top: 5px;
}
.r-5 {
	right: 5px;
}
.b-5 {
	bottom: 5px;
}
.l-5 {
	left: 5px;
}
.t--5 {
	top: -5px;
}
.r--5 {
	right: -5px;
}
.b--5 {
	bottom: -5px;
}
.l--5 {
	left: -5px;
}
.a-5 {
	top: 5px;
	right: 5px;
	bottom: 5px;
	left: 5px;
}
.x-5 {
	left: 5px;
	right: 5px;
}
.y-5 {
	left: 5px;
	right: 5px;
}
.t-10 {
	top: 10px;
}
.r-10 {
	right: 10px;
}
.b-10 {
	bottom: 10px;
}
.l-10 {
	left: 10px;
}
.t--10 {
	top: -10px;
}
.r--10 {
	right: -10px;
}
.b--10 {
	bottom: -10px;
}
.l--10 {
	left: -10px;
}
.a-10 {
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
}
.x-10 {
	left: 10px;
	right: 10px;
}
.y-10 {
	left: 10px;
	right: 10px;
}
.t-15 {
	top: 15px;
}
.r-15 {
	right: 15px;
}
.b-15 {
	bottom: 15px;
}
.l-15 {
	left: 15px;
}
.t--15 {
	top: -15px;
}
.r--15 {
	right: -15px;
}
.b--15 {
	bottom: -15px;
}
.l--15 {
	left: -15px;
}
.a-15 {
	top: 15px;
	right: 15px;
	bottom: 15px;
	left: 15px;
}
.x-15 {
	left: 15px;
	right: 15px;
}
.y-15 {
	left: 15px;
	right: 15px;
}
.t-20 {
	top: 20px;
}
.r-20 {
	right: 20px;
}
.b-20 {
	bottom: 20px;
}
.l-20 {
	left: 20px;
}
.t--20 {
	top: -20px;
}
.r--20 {
	right: -20px;
}
.b--20 {
	bottom: -20px;
}
.l--20 {
	left: -20px;
}
.a-20 {
	top: 20px;
	right: 20px;
	bottom: 20px;
	left: 20px;
}
.x-20 {
	left: 20px;
	right: 20px;
}
.y-20 {
	left: 20px;
	right: 20px;
}
.t-25 {
	top: 25px;
}
.r-25 {
	right: 25px;
}
.b-25 {
	bottom: 25px;
}
.l-25 {
	left: 25px;
}
.t--25 {
	top: -25px;
}
.r--25 {
	right: -25px;
}
.b--25 {
	bottom: -25px;
}
.l--25 {
	left: -25px;
}
.a-25 {
	top: 25px;
	right: 25px;
	bottom: 25px;
	left: 25px;
}
.x-25 {
	left: 25px;
	right: 25px;
}
.y-25 {
	left: 25px;
	right: 25px;
}
.t-30 {
	top: 30px;
}
.r-30 {
	right: 30px;
}
.b-30 {
	bottom: 30px;
}
.l-30 {
	left: 30px;
}
.t--30 {
	top: -30px;
}
.r--30 {
	right: -30px;
}
.b--30 {
	bottom: -30px;
}
.l--30 {
	left: -30px;
}
.a-30 {
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
}
.x-30 {
	left: 30px;
	right: 30px;
}
.y-30 {
	left: 30px;
	right: 30px;
}
.t-35 {
	top: 35px;
}
.r-35 {
	right: 35px;
}
.b-35 {
	bottom: 35px;
}
.l-35 {
	left: 35px;
}
.t--35 {
	top: -35px;
}
.r--35 {
	right: -35px;
}
.b--35 {
	bottom: -35px;
}
.l--35 {
	left: -35px;
}
.a-35 {
	top: 35px;
	right: 35px;
	bottom: 35px;
	left: 35px;
}
.x-35 {
	left: 35px;
	right: 35px;
}
.y-35 {
	left: 35px;
	right: 35px;
}
.t-40 {
	top: 40px;
}
.r-40 {
	right: 40px;
}
.b-40 {
	bottom: 40px;
}
.l-40 {
	left: 40px;
}
.t--40 {
	top: -40px;
}
.r--40 {
	right: -40px;
}
.b--40 {
	bottom: -40px;
}
.l--40 {
	left: -40px;
}
.a-40 {
	top: 40px;
	right: 40px;
	bottom: 40px;
	left: 40px;
}
.x-40 {
	left: 40px;
	right: 40px;
}
.y-40 {
	left: 40px;
	right: 40px;
}
.t-45 {
	top: 45px;
}
.r-45 {
	right: 45px;
}
.b-45 {
	bottom: 45px;
}
.l-45 {
	left: 45px;
}
.t--45 {
	top: -45px;
}
.r--45 {
	right: -45px;
}
.b--45 {
	bottom: -45px;
}
.l--45 {
	left: -45px;
}
.a-45 {
	top: 45px;
	right: 45px;
	bottom: 45px;
	left: 45px;
}
.x-45 {
	left: 45px;
	right: 45px;
}
.y-45 {
	left: 45px;
	right: 45px;
}
.t-50 {
	top: 50px;
}
.r-50 {
	right: 50px;
}
.b-50 {
	bottom: 50px;
}
.l-50 {
	left: 50px;
}
.t--50 {
	top: -50px;
}
.r--50 {
	right: -50px;
}
.b--50 {
	bottom: -50px;
}
.l--50 {
	left: -50px;
}
.a-50 {
	top: 50px;
	right: 50px;
	bottom: 50px;
	left: 50px;
}
.x-50 {
	left: 50px;
	right: 50px;
}
.y-50 {
	left: 50px;
	right: 50px;
}
.t-55 {
	top: 55px;
}
.r-55 {
	right: 55px;
}
.b-55 {
	bottom: 55px;
}
.l-55 {
	left: 55px;
}
.t--55 {
	top: -55px;
}
.r--55 {
	right: -55px;
}
.b--55 {
	bottom: -55px;
}
.l--55 {
	left: -55px;
}
.a-55 {
	top: 55px;
	right: 55px;
	bottom: 55px;
	left: 55px;
}
.x-55 {
	left: 55px;
	right: 55px;
}
.y-55 {
	left: 55px;
	right: 55px;
}
.t-60 {
	top: 60px;
}
.r-60 {
	right: 60px;
}
.b-60 {
	bottom: 60px;
}
.l-60 {
	left: 60px;
}
.t--60 {
	top: -60px;
}
.r--60 {
	right: -60px;
}
.b--60 {
	bottom: -60px;
}
.l--60 {
	left: -60px;
}
.a-60 {
	top: 60px;
	right: 60px;
	bottom: 60px;
	left: 60px;
}
.x-60 {
	left: 60px;
	right: 60px;
}
.y-60 {
	left: 60px;
	right: 60px;
}
.t-65 {
	top: 65px;
}
.r-65 {
	right: 65px;
}
.b-65 {
	bottom: 65px;
}
.l-65 {
	left: 65px;
}
.t--65 {
	top: -65px;
}
.r--65 {
	right: -65px;
}
.b--65 {
	bottom: -65px;
}
.l--65 {
	left: -65px;
}
.a-65 {
	top: 65px;
	right: 65px;
	bottom: 65px;
	left: 65px;
}
.x-65 {
	left: 65px;
	right: 65px;
}
.y-65 {
	left: 65px;
	right: 65px;
}
.t-70 {
	top: 70px;
}
.r-70 {
	right: 70px;
}
.b-70 {
	bottom: 70px;
}
.l-70 {
	left: 70px;
}
.t--70 {
	top: -70px;
}
.r--70 {
	right: -70px;
}
.b--70 {
	bottom: -70px;
}
.l--70 {
	left: -70px;
}
.a-70 {
	top: 70px;
	right: 70px;
	bottom: 70px;
	left: 70px;
}
.x-70 {
	left: 70px;
	right: 70px;
}
.y-70 {
	left: 70px;
	right: 70px;
}
.t-75 {
	top: 75px;
}
.r-75 {
	right: 75px;
}
.b-75 {
	bottom: 75px;
}
.l-75 {
	left: 75px;
}
.t--75 {
	top: -75px;
}
.r--75 {
	right: -75px;
}
.b--75 {
	bottom: -75px;
}
.l--75 {
	left: -75px;
}
.a-75 {
	top: 75px;
	right: 75px;
	bottom: 75px;
	left: 75px;
}
.x-75 {
	left: 75px;
	right: 75px;
}
.y-75 {
	left: 75px;
	right: 75px;
}
.t-80 {
	top: 80px;
}
.r-80 {
	right: 80px;
}
.b-80 {
	bottom: 80px;
}
.l-80 {
	left: 80px;
}
.t--80 {
	top: -80px;
}
.r--80 {
	right: -80px;
}
.b--80 {
	bottom: -80px;
}
.l--80 {
	left: -80px;
}
.a-80 {
	top: 80px;
	right: 80px;
	bottom: 80px;
	left: 80px;
}
.x-80 {
	left: 80px;
	right: 80px;
}
.y-80 {
	left: 80px;
	right: 80px;
}
.t-85 {
	top: 85px;
}
.r-85 {
	right: 85px;
}
.b-85 {
	bottom: 85px;
}
.l-85 {
	left: 85px;
}
.t--85 {
	top: -85px;
}
.r--85 {
	right: -85px;
}
.b--85 {
	bottom: -85px;
}
.l--85 {
	left: -85px;
}
.a-85 {
	top: 85px;
	right: 85px;
	bottom: 85px;
	left: 85px;
}
.x-85 {
	left: 85px;
	right: 85px;
}
.y-85 {
	left: 85px;
	right: 85px;
}
.t-90 {
	top: 90px;
}
.r-90 {
	right: 90px;
}
.b-90 {
	bottom: 90px;
}
.l-90 {
	left: 90px;
}
.t--90 {
	top: -90px;
}
.r--90 {
	right: -90px;
}
.b--90 {
	bottom: -90px;
}
.l--90 {
	left: -90px;
}
.a-90 {
	top: 90px;
	right: 90px;
	bottom: 90px;
	left: 90px;
}
.x-90 {
	left: 90px;
	right: 90px;
}
.y-90 {
	left: 90px;
	right: 90px;
}
.t-95 {
	top: 95px;
}
.r-95 {
	right: 95px;
}
.b-95 {
	bottom: 95px;
}
.l-95 {
	left: 95px;
}
.t--95 {
	top: -95px;
}
.r--95 {
	right: -95px;
}
.b--95 {
	bottom: -95px;
}
.l--95 {
	left: -95px;
}
.a-95 {
	top: 95px;
	right: 95px;
	bottom: 95px;
	left: 95px;
}
.x-95 {
	left: 95px;
	right: 95px;
}
.y-95 {
	left: 95px;
	right: 95px;
}
.t-100 {
	top: 100px;
}
.r-100 {
	right: 100px;
}
.b-100 {
	bottom: 100px;
}
.l-100 {
	left: 100px;
}
.t--100 {
	top: -100px;
}
.r--100 {
	right: -100px;
}
.b--100 {
	bottom: -100px;
}
.l--100 {
	left: -100px;
}
.a-100 {
	top: 100px;
	right: 100px;
	bottom: 100px;
	left: 100px;
}
.x-100 {
	left: 100px;
	right: 100px;
}
.y-100 {
	left: 100px;
	right: 100px;
}
.t-2 {
	top: 2px;
}
.r--5 {
	right: -5px;
}
.z-index-0 {
	z-index: 0;
}
.z-index-10 {
	z-index: 10;
}
.z-index-50 {
	z-index: 50;
}
.z-index-100 {
	z-index: 100;
}
.z-index-150 {
	z-index: 150;
}
.z-index-200 {
	z-index: 200;
}
@media (min-width: 480px) {
	.pos-xs-absolute {
		position: absolute;
	}
	.pos-xs-relative {
		position: relative;
	}
	.pos-xs-fixed {
		position: fixed;
	}
	.pos-xs-static {
		position: static;
	}
	.t-xs-auto {
		top: auto;
	}
	.r-xs-auto {
		right: auto;
	}
	.b-xs-auto {
		bottom: auto;
	}
	.l-xs-auto {
		left: auto;
	}
}
@media (min-width: 576px) {
	.pos-sm-absolute {
		position: absolute;
	}
	.pos-sm-relative {
		position: relative;
	}
	.pos-sm-fixed {
		position: fixed;
	}
	.pos-sm-static {
		position: static;
	}
	.t-sm-auto {
		top: auto;
	}
	.r-sm-auto {
		right: auto;
	}
	.b-sm-auto {
		bottom: auto;
	}
	.l-sm-auto {
		left: auto;
	}
}
@media (min-width: 768px) {
	.pos-md-absolute {
		position: absolute;
	}
	.pos-md-relative {
		position: relative;
	}
	.pos-md-fixed {
		position: fixed;
	}
	.pos-md-static {
		position: static;
	}
	.t-md-auto {
		top: auto;
	}
	.r-md-auto {
		right: auto;
	}
	.b-md-auto {
		bottom: auto;
	}
	.l-md-auto {
		left: auto;
	}
}
@media (min-width: 992px) {
	.pos-lg-absolute {
		position: absolute;
	}
	.pos-lg-relative {
		position: relative;
	}
	.pos-lg-fixed {
		position: fixed;
	}
	.pos-lg-static {
		position: static;
	}
	.t-lg-auto {
		top: auto;
	}
	.r-lg-auto {
		right: auto;
	}
	.b-lg-auto {
		bottom: auto;
	}
	.l-lg-auto {
		left: auto;
	}
}
@media (min-width: 1200px) {
	.pos-xl-absolute {
		position: absolute;
	}
	.pos-xl-relative {
		position: relative;
	}
	.pos-xl-fixed {
		position: fixed;
	}
	.pos-xl-static {
		position: static;
	}
	.t-xl-auto {
		top: auto;
	}
	.r-xl-auto {
		right: auto;
	}
	.b-xl-auto {
		bottom: auto;
	}
	.l-xl-auto {
		left: auto;
	}
}
  
  /* ###### Position  ###### */