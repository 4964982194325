/* ###### Profile  ###### */

/* ###### 7.8 Profile  ###### */

.main-content-profile {
  flex: 1;
}

@media (max-width: 991.98px) {
  .main-content-profile {
    .container, .container-fluid {
      display: block;
    }
  }
}

.main-content-left-profile {
  padding-left: 0;
  padding-right: 0;
  display: block;
  border-bottom: 1px solid #e2e8f5;
  padding-bottom: 25px;
  width: auto;
}

@media (min-width: 992px) {
  .main-content-left-profile {
    width: 270px;
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid #e2e8f5;
    border-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .main-content-left-profile {
    padding-right: 25px;
  }
}

.main-profile-overview {
  .main-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;

    &::after {
      display: none;
    }
  }

  .btn-icon-list {
    .btn, .sp-container button {
      border-radius: 100%;
    }
  }
}

.sp-container .main-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.main-profile-name {
  color: #1c273c;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
}

.main-profile-name-text {
  color: $secondary;
  font-size: 13px;
  margin-bottom: 0;
}

.main-profile-bio {
  font-size: 13px;
  margin-bottom: 20px;
}

.main-profile-social-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 20px;
    }
  }

  .media-icon {
    font-size: 34px;
    width: 30px;
    line-height: 0;
  }

  .media-body {
    margin-left: 20px;

    span {
      display: block;
      font-size: 12px;
    }

    a {
      font-size: 13px;
    }
  }
}

.main-content-body-profile {
  .nav {
    flex-direction: column;
    padding: 20px 20px 20px 0;
    border-bottom: 1px solid #e2e8f5;
  }

  .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: $dark;
    padding: 7px 20px 5px 20px;

    &.active {
      font-weight: 700;
      color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}

@media (min-width: 576px) {
  .main-content-body-profile {
    .nav {
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .main-nav-line .nav-link {
      padding: 7px 20px 5px 20px;

      &.active::before {
        left: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .main-content-body-profile .nav {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .main-content-body-profile .nav {
    padding-left: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    height: 2px;
  }
}

@media (min-width: 576px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    bottom: -11px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

.main-profile-body {
  padding: 15px 0 0;
}

@media (min-width: 576px) {
  .main-profile-body {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .main-profile-body {
    padding: 25px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .main-profile-body {
    padding-left: 25px;
  }
}

.main-profile-view-chart {
  position: relative;
  width: calc(100% - 10px);
  height: 200px;
}

@media (min-width: 375px) {
  .main-profile-view-chart {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .main-profile-view-chart {
    height: 250px;
  }
}

.main-profile-view-info {
  position: absolute;
  top: 0;
  left: 0;

  h6 {
    font-size: 32px;
    font-weight: 500;
    color: #1c273c;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    color: $pink;
    margin-left: 5px;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.main-traffic-detail-item {
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;

    > span {
      &:first-child {
        color: $secondary;
      }

      &:last-child {
        font-size: 11px;
        font-weight: 700;
        color: #1c273c;

        span {
          color: $secondary;
          font-weight: 400;
        }
      }
    }
  }

  + .main-traffic-detail-item {
    margin-top: 25px;
  }

  .progress {
    height: 8px;
  }
}

.main-profile-work-list {
  .media + .media {
    margin-top: 25px;
  }

  .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: $white;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
  }

  .media-body {
    margin-left: 20px;

    h6 {
      color: #1c273c;
      font-weight: 500;
      margin-bottom: 2px;
    }

    span {
      display: block;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: $secondary;
    }
  }
}

.main-profile-contact-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 25px;
    }
  }

  .media-icon {
    line-height: 0;
    font-size: 36px;
    width: 35px;
    text-align: center;
  }

  .media-body {
    margin-left: 25px;

    span {
      font-size: 12px;
      color: $secondary;
      display: block;
      line-height: 1.3;
    }

    div {
      font-weight: 500;
      color: #1c273c;
    }
  }
}
.main-header-message {
    position: relative;
    cursor: pointer;
}
/* ###### Profile  ###### */