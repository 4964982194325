/* ###### Main-footer  ###### */

/* ###### 6.2 Footer  ###### */

.main-footer {
  background-color: $white;
  border-top: 1px solid #e2e8f5;
  margin-top: auto;
  bottom: 0;
}

@media (min-width: 992px) {
  .main-footer {
    display: block;
  }

  .sticky-pin.main-header {
    background: #fefeff;
    border-radius: 12px;
    border-bottom: 0;
    margin: 0;
    background: $white;
    box-shadow: 0 0 60px 0 #e4e7eb;
    border-radius: 0;
    width: 100%;
    box-shadow: $shadow;
    z-index: 9999 !important;
  }
}

.app.sidebar-mini {
  .main-header.main-header-fixed.fixed-header {
    position: fixed;
    top: 0;
    z-index: 1 !important;
    width: 100%;
    margin: 0;
    border-radius: 0;
    left: 0;
    padding-left: 320px;
  }

  &.sidenav-toggled .main-header.main-header-fixed.fixed-header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin: 0;
    border-radius: 0;
    left: 0;
    padding-left: 70px;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin: 0;
    border-radius: 0;
    left: 0;
    padding-left: 70px;
  }

  &.sidenav-toggled1 .main-header.main-header-fixed.fixed-header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin: 0;
    border-radius: 0;
    left: 0;
    padding-left: 0;
  }
}

.main-header.main-header-fixed.fixed-header .container-fluid {
  margin-top: 0;
}

.side-header {
  position: sticky;
  width: 100%;
  z-index: 10 !important;
  border-bottom: 1px solid #e1e6f1;
}

.main-footer {
  .container {
    font-size: 12px;
    padding: 38px 20px 0;
    align-items: center;
    justify-content: space-between;
    color: $secondary;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    line-height: 3.7;
  }

  .container-fluid {
    font-size: 12px;
    padding: 38px 20px 0;
    align-items: center;
    justify-content: space-between;
    color: $secondary;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    line-height: 3.7;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .main-footer .container {
    padding: 10px 0 0;
  }
}

.main-footer-app {
  .container, .container-fluid {
    border-top-width: 0;
  }
}

.main-footer-demo {
  background-color: #0c025b;
  color: $white-4;
  height: 60px;

  .container {
    border-top-width: 0;
  }

  a {
    color: $white-5 ;

    &:hover, &:focus {
      color: $white;
    }
  }
}

/* ###### Main-footer  ###### */