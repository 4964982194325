/* ########## Contacts ########## */

/* ###### 7.4 Contacts  ###### */

@media (min-width: 768px) and (max-width: 991.98px) {
  .main-content-body-show {
    .main-header-contacts .main-header-menu-icon {
      display: flex;
    }

    .main-content-left-contacts {
      display: block;
    }
  }
}


.main-content-left-contacts {
  width: 100%;
  display: block;
  padding: 0;

  .main-content-breadcrumb, .main-content-title {
    padding-left: 20px;
  }
}

@media (min-width: 768px) {
  .main-content-left-contacts {
    border-right: 1px solid #e2e8f5;
  }
}

@media (min-width: 1300px) {
	#mainContactList, #mainContactList1 {
		height: 570px !important;
		max-height: 570px !important;
		position: relative;
	}
}
@media (min-width: 992px) {
  .main-content-left-contacts {
    .main-content-breadcrumb, .main-content-title {
      padding-left: 0;
    }
  }
}

.main-contact-label {
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 12px;
  color: #97a3b9;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e2e8f5;
    z-index: 5;
  }
}

@media (min-width: 992px) {
  .main-contact-label {
    padding-left: 30px;
  }
}

.main-contact-item {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-right-width: 0;
  display: flex;
  position: relative;
  cursor: pointer;

  + .main-contact-item {
    margin-top: -1px;
    border-top-color: #e4ebf7;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 65px;
      right: 0;
      border-top: 1px solid #e4ebf7;
    }
  }

  &:hover, &:focus {
    background-color: #f6f9fd;
    border-top-color: #e4ebf7;
    border-bottom-color: #e4ebf7;
  }

  &.selected {
    z-index: 1;
    border-left-color: $primary;
    border-top-color: #e4ebf7;
    border-bottom-color: #e4ebf7;
    background-color: #f4f6ff;
  }
}

@media (min-width: 992px) {
  .main-contact-item {
    padding: 10px 15px;
  }
}

.main-contact-body {
  flex: 1;
  margin-left: 15px;

  h6 {
    color: #1c273c;
    font-weight: 500;
    margin-bottom: 2px;
  }

  span {
    font-size: 13px;
    color: $secondary;
  }
}

.main-contact-star {
  font-size: 16px;
  color: #97a3b9;

  &:hover, &:focus {
    color: $secondary;
  }

  &.active {
    color: $warning;
  }
}

.main-content-body-contacts {
  display: none;
}

@media (min-width: 768px) {
  .main-content-body-contacts {
    display: block;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .main-content-body-contacts {
    overflow: visible;
  }
}

.main-contact-info-header {
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e8f5;
  position: relative;

  .media {
    display: block;
  }

  .main-img-user {
    width: 100px;
    height: 100px;

    &::after {
      display: none;
    }

    a {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background-color: $secondary;
      color: $white;
      font-size: 18px;
      line-height: .9;
      box-shadow: 0 0 0 2px $white;
      border-radius: 100%;

      &:hover, &:focus {
        background-color: $primary;
      }
    }
  }

  .media-body {
    margin-top: 30px;

    h4 {
      color: #1c273c;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 1;
    }

    p {
      color: #031b4e;
      margin-bottom: 15px;
    }

    .nav-link {
      padding: 0;
      display: flex;
      align-items: center;
      color: $secondary;
      font-size: 13px;

      i {
        font-size: 0.875rem;
        line-height: 0;
        margin-right: 5px;

        &.typcn {
          line-height: .9;

          &::before {
            width: auto;
          }
        }
      }

      &:hover, &:focus {
        color: $primary;
      }

      + .nav-link {
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: 576px) {
  .main-contact-info-header {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .main-contact-info-header {
    padding-left: 30px;
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .main-contact-info-header .media {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .main-contact-info-header .media-body {
    margin-top: 0;
    margin-left: 30px;
  }
}

@media (min-width: 576px) {
  .main-contact-info-header .media-body .nav-link {
    font-size: 0.875rem;
  }
}

@media (min-width: 576px) {
  .main-contact-info-header .media-body .nav-link i {
    margin-right: 7px;
  }
}

@media (min-width: 576px) {
  .main-contact-info-header .media-body .nav-link + .nav-link {
    margin-left: 25px;
  }
}

.main-contact-action {
  position: absolute;
  top: 24px;
  right: 20px;
  display: flex;

  a {
    display: flex;
    align-items: center;
    color: $secondary;
    font-size: 12px;

    &:hover, &:focus {
      color: $primary;
    }

    i {
      font-size: 21px;
      line-height: .5;
    }

    + a {
      margin-left: 15px;
    }
  }
}

@media (min-width: 992px) {
  .main-contact-action {
    right: 20px;
  }
}

.main-contact-info-body {
  padding-left: 20px;

  .media-list {
    padding: 20px 0;
  }

  .media {
    align-items: center;
    position: relative;

    + .media {
      margin-top: 30px;

      &::before {
        content: '';
        position: absolute;
        top: -15px;
        left: -19px;
        right: 0;
        border-top: 1px dotted #e2e8f5;
      }
    }

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .media-icon {
    font-size: 32px;
    color: #97a3b9;
    line-height: 1;
    width: 40px;
    text-align: center;
  }

  .media-body {
    margin-left: 0;

    > div + div {
      margin-top: 15px;
    }

    label {
      color: $secondary;
      margin-bottom: 0;
      display: block;
      font-size: 13px;
    }

    span {
      display: block;
      color: #1c273c;
      font-weight: 500;
    }
  }
}

@media (min-width: 992px) {
  padding-left: 0;

  .main-contact-info-body {
    height: calc(100% - 126px);
    position: relative;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-list {
    padding: 25px 0;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-icon {
    width: 100px;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-body {
    display: flex;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-body > div {
    flex: 1;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-body > div + div {
    margin-top: 0;
  }
}

/* contacts */

.media-main {
  .info {
    overflow: hidden;
    color: $black;
  }

  a.pull-left {
    width: 100px;
  }

  .info h4 {
    padding-top: 10px;
    margin-bottom: 5px;
  }
}

.thumb-lg {
  height: 84px;
  width: 84px;
}

.media-main .info {
  overflow: hidden;
  color: $black;

  h4 {
    padding-top: 10px;
    margin-bottom: 5px;
  }
}

.contact-links {
  li {
    a {
      background: #eef1f9;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      color: #7A7676;
      margin-right: 20px;
    }

    &:last-child a {
      margin-right: 0;
    }
  }

  text-align: center;
  margin: 0 auto;

  .contact-icon {
    text-align: center;
    line-height: 26px;
    width: 24px;
    height: 24px;
    fill: #717b98;
    justify-content: center;
    border-radius: 0;
  }
}

.contact-actions-icons {
  top: 18px;
  right: 18px;
  position: absolute;
}

.fav-contact, .delete-contact {
  right: 20px;
  width: 21px;
  height: 21px;
  fill: $white;
}

.fav-contact {
  margin-right: 0.3rem;
}

.contact-details {
  margin-top: 22px;
}

.contact-name {
  font-size: 15px;
  font-weight: 600;
}

.contact-num {
  font-size: 14px;
  font-weight: 400;
  color: #1e2a54;
  margin-bottom: 11px;
}

.contact-mail {
  font-size: 14px;
  font-weight: 400;
  color: #556290;
  margin-bottom: 14px;
}

.contact-links {
  display: inline-flex;
}

.contact-tab {
  .input-group {
    width: initial;
  }

  .nav-link {
    &.active {
      color: $primary;
    }

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 7px 20px 0 20px;
  }
}

#example-input1-group2 {
  width: 200px;
  max-width: 200px;
}

.contact-table .table {
  th, td {
    vertical-align: middle;
  }
}

#editimage {
  margin-left: 36px;
}

#data tr {
  display: none;
}

#contact-table img {
  max-width: inherit;
}

#contactTable_wrapper table.dataTable thead {
  .sorting, .sorting_asc {
    background-image: inherit;
  }

  .sorting {
    &:before, &:after {
      position: absolute;
      bottom: 0.9em;
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }
  }

  .sorting_asc {
    &:before, &:after {
      position: absolute;
      bottom: 0.9em;
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }
  }

  .sorting_desc {
    &:before, &:after {
      position: absolute;
      bottom: 0.9em;
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }
  }

  .sorting_asc_disabled {
    &:before, &:after {
      position: absolute;
      bottom: 0.9em;
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }
  }

  .sorting_desc_disabled {
    &:before, &:after {
      position: absolute;
      bottom: 0.9em;
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }
  }
}

#contact-pagination {
  margin-top: 0;
  border-radius: 50px;
  background: #f1f4fb;
  padding: 5px;
  width: 200px;

  a {
    &.active {
      background: $primary;
      color: $white;
    }

    z-index: 1;
    color: $black;
    padding: 6px 10px;
    border-radius: 50px !important;
    border: none;
    margin: 0 1px;
    min-width: 32px;
    height: 32px;
    width: 32px;
    line-height: 33px;
    text-align: center;
    font-size: 14px;
  }
}

.contact-tab .btn-search {
  background: transparent;
  border: 1px solid #e2e8f5;
  border-left: 0;
  height: 38px;
  padding: 9px 13px;

  i {
    color: #a4b1ce;
  }
}

.contact-table {
  .page-link:hover {
    border: 0 !important;
    box-shadow: none !important;
  }

  .page-item {
    &:first-child .page-link:hover, &:last-child .page-link:focus, &:first-child:hover, &:last-child:focus {
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
    }

    &.current-page.active .page-link {
      &:hover, &:focus {
        background: $primary;
      }
    }
  }
}
.contact-table .table {
  th, tr {
    border-bottom: 1px solid #e4ebf7;
  }

  td {
    border-top: 0;
  }
}

.contact-table-msg #errmsg {
  position: absolute;
}

.pagination-radius.contact-one .page-item {
  &:first-child .page-link, &:last-child .page-link {
    padding: 10px 26px;
  }

  &:first-child .page-link:hover, &:last-child .page-link:hover {
    border: 1px solid #dee4f5 !important;
  }
}

.main-mail-checkbox .ckbox {
  display: inline-block;
}

#mainContactList, #mainContactList1 {
  height: 470px;
  max-height: 470px;
  position: relative;
}

.contact-table {
  .main-mail-checkbox {
    display: block;
  }

  #previous-page .page-link:hover {
    background: #d8e1f9 !important;
    color: #596882;
  }
}

/* ########## Contacts ########## */