/* ######  Error  ###### */

/* ###### 7.9 Signin  ###### */

.main-signin-wrapper {
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-card-signin {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  position: relative;
}

.main-card-signup {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: $white-5 ;
  position: relative;
}

@media (min-width: 576px) and (max-width: 1026px) {
  .wizard > .steps > ul li .title {
    display: none !important;
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .main-card-signin {
    display: block !important;
    padding: 0;
  }

  .main-header-center {
    .header-breadcrumb {
      display: none;
    }

    .responsive-logo {
      display: block;

      .mobile-logo {
        height: 2.1rem;
        text-align: center;
        margin: 10px;
      }
    }
  }

  .page-signin-style:before {
    display: none;
  }

  .main-nav-line-chat {
    padding: 20px !important;
  }

  .main-header {
    margin: 0 !important;
    border-radius: 0  !important;
  }

  .main-content {
    .container, .container-fluid {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .main-header.main-header-fixed {
    height: 70px;
    position: fixed;
    width: 100%;
    left: 0;
  }

  .main-content-body {
    margin-top: 93px;

    &.main-content-body-calendar {
      margin-top: 0;
    }
  }

  .main-header.main-header-fixed.fixed-header {
    padding-left: 0 !important;
  }
  .second-sidemenu {
    top: 70px;
    border-top: 1px solid #dce7f5;
  }

  .first-sidemenu {
    top: 70px;
    border-top: 1px solid #dce7f5;

    .fav-icon {
      display: none;
    }
  }

  .main-card-signin {
    padding: 2rem !important;
    border: 1px solid #dce7f5 !important;

    .p-5.wd-100p {
      padding: 0 !important;
    }
  }

  .card-sigin {
    padding: 2rem !important;
    margin: 2rem !important;
    border: 1px solid #dce7f5 !important;
  }
  .main-card-signin.border-0 {
    border: 1px solid #dce7f5 !important;
  }
}

@media (max-width: 1070px) {
  .main-header-center .header-breadcrumb {
    display: none;
  }

  .main-content-body .header-title {
    display: block;
    display: flex;
  }
}

@media (max-width: 1070px) and (min-width: 520px) {
  .main-content-body .header-title {
    display: block;
    display: flex;
  }
}

@media (max-width: 526px) {
  .main-content-body .header-title {
    display: block;
  }
   #count-down .clock-presenter .digit {
		line-height: 38px  !important;
	}
	#count-down .clock-presenter {
		width: 50px !important;
		height: 50px  !important;
		float: left;
		margin: 0 9px 0 0  !important;
		background: url(../img/photos/pattern.png);
		border-radius: 3px;
		background-size: cover;
		position: relative;
		line-height: 13px;
	}
	.construction.main-card-signin {
		padding: 0 !important;
	}
	
}

@media (min-width: 1071px) {
  .main-content-body .header-title {
    display: none;
  }

  .main-header-center {
    margin-left: 20px !important;
  }
}

@media (max-width: 991px) {
  .main-content-body-show .main-header-arrow {
    display: block;
    padding: 10px 20px;
    background: $primary;
    color: $white;
  }
}

@media (min-width: 480px) {
  .main-card-signin {
    border: 0;
    padding: 0;
    max-width: 750px;

    &.construction {
      max-width: 1000px;
    }
  }

  .main-card-signup {
    border: 1px solid #e3e3e3;
    padding: 0;
    max-width: 850px;
  }

  .sign-up-body {
    padding: 2.6rem !important;
  }

  .forgot-password img {
    width: 100%;
  }
}

.main-signin-header {
  h2 {
    font-weight: 500;
    color: $primary;
    letter-spacing: -1px;
  }

  h4 {
    font-weight: 500;
    color: #14112d;
    margin-bottom: 25px;
    font-size: 16px;
  }

  label {
    color: #313e61;
    font-weight: 500;
  }

  .form-control {
    color: #14112d;
    font-weight: 500;
    border-width: 2px;
    border-color: #e3e8f7;

    &:focus {
      border-color: #b4bdce;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: #a5a0b1;
    }
  }
}

.page-signin-style {
  background: #e6eefb;
}

.main-signin-header {
  .btn, .sp-container button {
    margin-top: 25px;
  }
}

.sp-container .main-signin-header button {
  margin-top: 25px;
}

.main-signin-footer {
  p {
    color: #a5a0b1;

    &:first-child {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #14112d;
    font-weight: 700;

    &:hover, &:focus {
      color: $primary;
    }
  }
}

/* ###### 7.10 Signup  ###### */

.main-signup-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(244, 245, 248, 0.2);
}

@media (min-width: 992px) {
  .main-signup-wrapper {
    justify-content: flex-end;
  }
}

.main-column-signup-left {
  flex: 1;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;

  > div {
    max-width: 500px;
  }

  .typcn {
    font-size: 80px;
    margin-bottom: 50px;

    &::before {
      width: auto;
    }
  }

  .main-logo {
    font-size: 40px;
    line-height: 1;
  }

  h5 {
    color: #14112d;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
  }

  p {
    line-height: 1.7;
    margin-bottom: 25px;
  }

  .btn, .sp-container button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
  }
}

@media (min-width: 576px) {
  .main-column-signup-left {
    padding: 40px;
  }
}

@media (min-width: 992px) {
  .main-column-signup-left {
    display: flex;
  }
}

.sp-container .main-column-signup-left button {
  border-width: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 700;
  text-transform: uppercase;
  height: 38px;
}

.main-column-signup {
  background-color: $white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .main-column-signup {
    padding: 40px;
    width: 500px;
  }
}

@media (min-width: 992px) {
  .main-column-signup {
    border-left: 1px solid #e3e3e3;
  }
}

.main-signup-header {
  h2 {
    font-weight: 500;
    color: $primary;
    letter-spacing: -1px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    color: #14112d;
    margin-bottom: 25px;
  }

  label {
    color: #06153e;
    font-weight: 500;
  }

  .form-control {
    color: #14112d;
    font-weight: 500;
    border-width: 2px;
    border-color: #dee2ea;

    &:focus {
      border-color: #b4bdce;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: #a5a0b1;
    }
  }

  > .btn {
    margin-top: 25px;
  }
}

.sp-container .main-signup-header > button {
  margin-top: 25px;
}

.main-signup-header .row {
  margin-top: 20px;

  > div {
    .btn i, .sp-container button i {
      font-size: 15px;
      line-height: 0;
      margin-right: 5px;
    }
  }
}

.sp-container .main-signup-header .row > div button i {
  font-size: 15px;
  line-height: 0;
  margin-right: 5px;
}

.main-signup-header .row > div:first-child {
  .btn, .sp-container button {
    background-color: #4267b2;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button {
  background-color: #4267b2;
  color: $white;
}

.main-signup-header .row > div:first-child {
  .btn:hover, .sp-container button:hover {
    background-color: #375694;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button:hover {
  background-color: #375694;
  color: $white;
}

.main-signup-header .row > div:first-child {
  .btn:focus, .sp-container button:focus {
    background-color: #375694;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button:focus {
  background-color: #375694;
  color: $white;
}

.main-signup-footer {
  p {
    color: #8692b3;
    margin-bottom: 0;
  }

  a {
    color: #14112d;
    font-weight: 700;

    &:hover, &:focus {
      color: $primary;
    }
  }
}

/* ###### 7.11 Error  ###### */

.main-error-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  h1 {
    line-height: 1;
    font-size: 150px;
    font-weight: 700;
    color: #1c273c;
  }

  h2 {
    font-weight: 500;
    letter-spacing: -.5px;
    margin-bottom: 15px;
  }

  h6 {
    margin-bottom: 40px;
    font-size: 14px;
  }

  .btn, .sp-container button {
    border-width: 2px;
    font-weight: 700;
  }
}

.sp-container .main-error-wrapper button {
  border-width: 2px;
  font-weight: 700;
}

/* ######  Error  ###### */