/* ###### Account-setting  ###### */



.account-setting-tab-menu {

  .list-group {
    margin-bottom: 0;

    > a {
      margin-bottom: 0;

      .glyphicon, .fa {
        color: $primary;
      }

      &:first-child {
        border-top-right-radius: 0;
        -moz-border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-right-radius: 0;
        -moz-border-bottom-right-radius: 0;
      }

      &.active {
			background-color: rgb(244, 246, 255);
			color: $primary;
			border-color: #e7ebf3;
			border-left: 2px solid $primary;
      }
    }
  }
}

.account-setting-tab-content {
  background-color:$white;
  padding-left: 20px;
  padding-top: 10px;
}

.account-setting-tab .account-setting-tab-content:not(.active) {
  display: none;
}

.account-setting-tab-menu .list-group > a.list-group-item {
  padding: 0.7rem 1.25rem;
  border-radius: 0;
}

.change-profile {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  direction: ltr;
  cursor: pointer;
  z-index: 2;
}

.account-setting-tab-content .list-group-header {
  margin: 0;
  padding: 1.5rem 1rem 0 1.5rem;
  font-size: 12px;
  font-weight: 600;
  color: #2b334a;
  text-transform: uppercase;
}

.profile-image {
  position: relative;
}

/* ###### Account-setting  ###### */