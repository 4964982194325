/* ###### Backgrounds ###### */

/* ###### 9.1 Background  ###### */

.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color:$gray-300;
}

.bg-gray-400 {
  background-color: $gray-400;
}

.bg-gray-500 {
  background-color: $gray-500;
}

.bg-gray-600 {
  background-color: $gray-600;
}

.bg-gray-700 {
  background-color: $gray-700;
}

.bg-gray-800 {
  background-color: $gray-800;
}

.bg-gray-900 {
  background-color: $gray-900;
}

.bg-white-1 {
  background-color: $white-1;
}

.bg-white-2 {
  background-color: $white-2;
}

.bg-white-3 {
  background-color: $white-3;
}

.bg-white-4 {
  background-color: $white-4;
}

.bg-white-5 {
  background-color: $white-5 ;
}

.bg-white-6 {
  background-color: $white-6;
}

.bg-white-7 {
  background-color: $white-7;
}

.bg-white-8 {
  background-color: $white-8;
}

.bg-white-9 {
  background-color: $white-9;
}

.bg-black-1 {
  background-color: $black-1;
}

.bg-black-2 {
  background-color: $black-2;
}

.bg-black-3 {
  background-color: $black-3;
}

.bg-black-4 {
  background-color:$black-4;
}

.bg-black-5 {
  background-color: $black-5;
}

.bg-black-6 {
  background-color: $black-6;
}

.bg-black-7 {
  background-color: $black-7;
}

.bg-black-8 {
  background-color: $black-8;
}

.bg-black-9 {
  background-color: $black-9;
}

.bg-indigo {
  background-color: $primary;
}

.bg-purple {
  background-color: $purple;
}

.bg-pink {
  background-color: $pink;
}

.bg-orange {
  background-color: $orange;
}

.bg-teal {
  background-color: $teal !important;
}

.bg-purple-dark {
  background-color: #59339d;
}

.bg-primary-transparent {
  background-color: var(--primary-transparentcolor);
}

.bg-primary-transparent-2 {
  background-color: rgba(68, 84, 195, 0.15);
}

.bg-secondary-transparent-2 {
  background-color: rgba(247, 45, 102, 0.15);
}

.bg-success-transparent-2 {
  background-color: rgba(45, 206, 137, 0.15);
}

.bg-info-transparent-2 {
  background-color: rgba(69, 170, 242, 0.15);
}

.bg-warning-transparent-2 {
  background-color: rgba(236, 180, 3, 0.15);
}

.bg-danger-transparent-2 {
  background-color: rgba(255, 91, 81, 0.15);
}

/* ###### 4.8 Box-shadows ###### */

.box-shadow-primary {
  box-shadow: 0 5px 10px rgba(40, 92, 247, 0.25);
}

.box-shadow-success {
  box-shadow: 0 5px 10px rgba(59, 176, 1, 0.25);
}

.box-shadow-warning {
  box-shadow: 0 5px 10px rgba(255, 193, 7, 0.25);
}

.box-shadow-danger {
  box-shadow: 0 5px 10px rgba(220, 53, 69, 0.25);
}

.box-shadow-pink {
  box-shadow: 0 5px 10px rgba(241, 0, 117, 0.25);
}

/* ###### 4.8 transparent colors ###### */

.bg-success-transparent {
  background-color: #d4f2e1 !important;
}

.bg-danger-transparent {
  background-color: #f9e2e2 !important;
}
.bg-warning-transparent {
  background-color: rgb(253, 246, 222) !important;
}

.bg-pink-transparent {
  background-color: #fee5f1 !important;
}

.bg-purple-transparent {
  background-color: #e1defe !important;
}

.bg-teal-transparent {
  background-color: #d2f5f5 !important;
}

.bg-info-transparent {
  background-color: rgba(23, 162, 184, 0.2) !important;
}

.bg-orange-transparent {
  background-color: rgba(253, 126, 20, 0.2);
}

/* ###### Backgrounds ###### */