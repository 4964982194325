/* ###### Timeline  ###### */

/* Timeline */
/*--- Vertical --*/

.vtimeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 20px;

  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d1d4e4;
    left: 50%;
    margin-left: -1.5px;
  }

  .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    .timeline-panel {
      border-radius: 2px;
      padding: 20px;
      position: relative;
      background: #f3f6ff;
      border-radius: 0px;
      box-shadow: none;
      width: 35%;
      margin-left: 15%;

      &:before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;
        content: "";
        left: 0;
        right: 0;
      }

      &:after {
        position: absolute;
        top: 10px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #f3f6ff;
        border-right: 0 solid #f3f6ff;
        border-bottom: 14px solid transparent;
        content: " ";
      }

      .timeline-title {
        font-size: 1rem;
        font-weight: 700;
        margin: 0 0 .625rem;
      }

      .timeline-body {
        p + p {
          margin-top: 5px;
        }

        ul {
          margin-bottom: 0;
        }
      }

      .timeline-footer span {
        font-size: .6875rem;
      }
    }

    .timeline-badge {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 16px;
      left: calc(50% - 7px);
      z-index: 0;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      border: 2px solid $white;

      i {
        color: $white;
      }
    }

    &.timeline-inverted {
      padding-right: 0;
      padding-left: 90px;

      .timeline-panel {
        margin-left: auto;
        margin-right: 15%;

        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
  }
}

.timeline-wrapper-primary {
  .timeline-panel:before, .timeline-badge {
    background: $primary;
  }
}

.timeline-wrapper-secondary {
  .timeline-panel:before, .timeline-badge {
    background: #eb6f33;
  }
}

.timeline-wrapper-success {
  .timeline-panel:before, .timeline-badge {
    background: $success;
  }
}

.timeline-wrapper-info {
  .timeline-panel:before, .timeline-badge {
    background: #01b8ff;
  }
}

.timeline-wrapper-warning {
  .timeline-panel:before, .timeline-badge {
    background: $warning;
  }
}

.timeline-wrapper-danger {
  .timeline-panel:before, .timeline-badge {
    background: #ff5353;
  }
}

.timeline-wrapper-light {
  .timeline-panel:before, .timeline-badge {
    background: #f1f2f9;
  }
}

.timeline-wrapper-dark {
  .timeline-panel:before, .timeline-badge {
    background: #1c273c;
  }
}

@media (max-width: 991px) {
  .timeline .btn {
    font-size: 12px;
  }

  .vtimeline {
    &:before {
      left: 6px !important;
    }

    .timeline-wrapper {
      .timeline-badge {
        left: 0 !important;
      }

      padding-right: 0 !important;

      .timeline-panel {
        width: auto !important;
        margin-left: 10% !important;

        &:after {
          border-right: 14px solid #f3f6ff !important;
          border-left: 0 solid #cad4e4 !important;
          left: -14px !important;
          right: auto !important;
        }
      }

      &.timeline-inverted {
        padding-left: 0 !important;

        .timeline-panel {
          margin-right: 0 !important;
          width: auto !important;
        }
      }
    }
  }
}

/*latetes news timeline */

.latest-timeline-1 ul.timeline-1 {
  list-style-type: none;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    border-left: 2px dotted #c0ccda;
  }

  > li {
    a {
      color: $black;
    }

    .date {
      font-size: 10px;
    }

    p {
      font-size: 13px;
    }

    margin: 25px 0;
    padding-left: 0.90rem;

    .product-icon {
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      left: 1px;
      background-color: #f3f6f7;
      font-size: 20px;
      line-height: 37px;
      font-size: 21px;
    }

    &:nth-child(2):before {
      border: 3px solid #fb1c52;
    }

    &:nth-child(3):before {
      border: 3px solid #e9b307;
    }

    &:nth-child(4):before {
      border: 3px solid #4481eb;
    }

    &:last-child:before {
      border: 3px solid #27af06;
    }
  }
}

/* ###### Timeline  ###### */