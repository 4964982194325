/* ###### Progress ###### */

.progress {
  height: auto;
  border-radius: 6px;
}

.progress-bar {
  border-radius: 6px;
  height: 5px;
  font-size: 11px;
}

.progress-bar-xs {
  height: 5px;
}

.progress-bar-sm {
  height: 8px;
}

.progress-bar-lg {
  height: 15px;
}

.progress-style {
  &.progress {
    .progress-bar {
      box-shadow: none;
      border-radius: 0;
      position: relative;
      -webkit-animation: animate-positive 2s;
      animation: animate-positive 2s;
    }

    overflow: visible !important;
  }

  .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    border-radius: 20px !important;
    line-height: 20px;
    overflow: visible !important;
    color: $white;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
  }

  .bg-primary-gradient.progress-bar:after, .bg-primary.progress-bar:after {
    border: 4px solid $primary;
  }

  &.progress .progress-bar:after {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 5px 5px $black-2;
    background: $white;
    position: absolute;
    right: -5px;
    top: -5px;
  }

  .bg-danger.progress-bar:after {
    border: 4px solid #ee335e;
  }

  .bg-success.progress-bar:after {
    border: 4px solid #22c03c;
  }

  .bg-warning.progress-bar:after {
    border: 4px solid #fbbc0b;
  }

  .bg-info.progress-bar:after {
    border: 4px solid #00b9ff;
  }
}

.progress-sm {
  height: 5px;
}

.card-progress {
  display: flex;
  align-items: center;

  .progress {
    height: 3px;
    flex: 1;
    margin: 0 5px;
  }
}

.card-dashboard-progress {
  .progress-legend {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      position: relative;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1px;
      padding-left: 15px;
      line-height: .95;

      &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 8px;
        height: 8px;
      }

      &:first-child::before {
        background-color: $primary;
      }

      &:last-child::before {
        background-color: $teal;
      }

      + li {
        margin-left: 20px;
      }
    }
  }

  .media {
    display: block;

    > label {
      width: 50px;
      display: block;
      margin-bottom: 0;
    }

    + .media {
      margin-top: 15px;
    }
  }

  .media-body {
    margin-top: 5px;
  }

  .progress {
    background-color: #f6f9fd;
  }

  .progress-bar {
    height: 15px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;

    + .progress-bar {
      margin-left: 1px;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-progress .media {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .card-dashboard-progress .media-body {
    margin-top: 0;
    margin-left: 15px;
  }
}

/* ###### Progress ###### */