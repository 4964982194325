/* ###### Toast ###### */

.toast {
  background-color: rgba(255, 255, 255, 0.95);
  border-color: #dfe6f9;
  box-shadow: none;
  border-radius: 3px;
}

.toast-header {
  border-bottom-color:#dfe6f9;

  .close {
    font-weight: 400;
    color: #36445f;
    outline: none;
    opacity: 1;
  }
}

.demo-static-toast .toast {
  opacity: 1;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid $black-1;
  box-shadow:0 0.25rem 0.75rem rgb(237, 242, 255);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &.showing {
    opacity: 1;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	color: #36445f;
	background-color: #f6f8ff;
	background-clip: padding-box;
	border-bottom: 1px solid rgb(220, 227, 249);
}

.toast-body {
    padding: 0.75rem;
    background: #f6f8ff;
    color: #7683a2;
}


/* ###### Toast ###### */