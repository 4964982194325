/* ###### scrum-board  ###### */

.button-backlog {
  background: transparent;
  box-shadow: none;
  color: $primary;
  font-weight: normal;
  outline: none;
  border: 0;
}

.button-progress {
  background: transparent;
  box-shadow: none;
  color: #e8b210;
  font-weight: normal;
  outline: none;
  border: 0;
}

.button-onhold {
  background: transparent;
  box-shadow: none;
  color: #FF5722;
  font-weight: normal;
  outline: none;
  border: 0;
}

.button-done {
  background: transparent;
  box-shadow: none;
  color: $success;
  font-weight: normal;
  outline: none;
  border: 0;
}

.button-delete {
  background: transparent;
  box-shadow: none;
  color: #ff5353;
  font-weight: normal;
  outline: none;
  border: 0;
}

.form > h2 {
  margin-top: 0;
}

.col-3rds {
  box-sizing: border-box;
  width: 33.333%;
  float: left;
}

.flex {
  display: flex;
  flex-direction: row;
}

.scrum-board {
  flex: 1;
  padding: 0;

  .messages, .attachment {
    padding: 4px;
    background: #e3e7f7;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
}

.scrum-board-card .addtaskbtn {
    width: 100%;
    background: rgb(255, 255, 255);
    color: #000000;
    border: 1px dashed #d0dcec;
    position: unset;
    bottom: 0;
    height: 43px;
}

.scrum-board:first-child {
  flex: 1;
  padding: 0;
}

.inline {
  display: inline;
}

.margin-top-10 {
  margin-top: 10px;
}

.placeholder {
  display: block;
  background-color: $white;
  border: 5px dashed #ededed;
  min-height: 100px;
  margin-bottom: 10px;
}

.product-invoice img {
  max-width: inherit;
}

.scrum-board-card .input-group {
  padding: 1.3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: $white;
  box-shadow: 0 0 60px 0 #e4e7eb;
  border-radius: 6px !important;
  background-clip: border-box;
  border: 0;
  margin-bottom: 1.3rem;
  box-shadow: $shadow;
}

.scrumb-header {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
}

.scrum-board-item:before {
  content: '';
  position: absolute;
  width: 100%;
  border-radius: 5px;
  height: 2px;
  background: $primary;
  left: 0;
  top: 0;
}

.in-progress .scrum-board-item:before {
  content: '';
  position: absolute;
  width: 100%;
  border-radius: 5px;
  height: 2px;
  background: $warning;
  left: 0;
  top: 0;
}

.done .scrum-board-item:before {
  content: '';
  position: absolute;
  width: 100%;
  border-radius: 5px;
  height: 2px;
  background: $success;
  left: 0;
  top: 0;
}

.onhold .scrum-board-item:before {
  content: '';
  position: absolute;
  width: 100%;
  border-radius: 5px;
  height: 2px;
  background: #ff5353;
  left: 0;
  top: 0;
}

.scrum-board {
  &.done .scrum-board-item {
    background-color: rgb(255, 255, 255);
    border: 0;
  }

  &.backlog .scrum-board-item {
    background: $white;
    border: 0;
  }
}

.scrumb-card {
	background: rgb(210, 215, 234);
	box-shadow: none;
	margin: 0 8px 1.5rem 15px;
	padding: 0;
	min-width: 320px;
	width: 320px;
	border-radius: 4px;
	border: 1px solid #c9d0ea;

  .form {
    position: relative;
    padding: 13px;
    background: $white;
  }
}

.scrum-board.in-progress .scrum-board-item {
  background-color: rgb(255, 255, 255);
  border: 0;
}

.scrum-board-card {
  .addtask {
    position: relative;
  }

  .input-group-btn {
    position: absolute;
    float: right;
    right: 10px;
    line-height: 49px;
  }

  img {
    height: 150px;
    width: 300px;
  }

  .btn-primary {
    color: $primary;
    background-color: transparent;
    border-color: transparent;
    font-weight: 500;
  }

  .form-control {
    height: 50px;
    border-radius: 6px;
    border: 0;
    border: 1px solid #e0e6f0;
  }

  overflow-x: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}

.scrumb-backlog, .scrumb-progress, .scrumb-onhold, .scrumb-done {
  max-height: 100%;
  height: 600px;
}

.scrum-board-card {
  .dropdown-menu {
    top: 25px !important;
    left: 39px !important;
    transform: translate3d(-162px, 0px, 0px);
    box-shadow: 0px 8px 16px 0px $black-2;
    z-index: 1;
  }

  #addtask {
    position: relative;
  }
}

.task-status {
  list-style-type: none;
  display: flex;
  float: left;
  text-align: left;
  padding-left: 0;
  width: 100%;

  a {
    border-radius: 57px;
    height: 4px;
    max-width: 32px;
    display: block;
    width: 44px;
    margin-right: 8px;
    border: 1px solid $black-1;
  }
}

.scrum-board-card {
  .add-img i {
    color: $black;
  }

  .avatar-list-stacked .avatar {
    margin-right: -.4em !important;
  }
}

.task-status {
  .status-backlog {
    background-color: $primary;
  }

  .status-progress {
    background-color: $warning;
  }

  .status-completed {
    background-color: $success;
  }

  .status-onhold {
    background-color: #ff5353;
  }
}

.scrum-board-card .input-group p {
  width: 100%;
}

/* ###### scrum-board  ###### */